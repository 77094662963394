import api from '@/utils/request'

// 教师风采详情
// /teacherStyle/getTeacherStyleById
export function getTeacherStyleById(params) {
  return api({
    method: 'get',
    url: '/teacherStyle/getTeacherStyleById',
    params,
  })
}
export function getTeacherStyleVOList(params) {
  return api({
    method: 'get',
    url: '/teacherStyle/getTeacherStyleVOList',
    params,
  })
}
export function getTeacherQrCode(params) {
  return api({
    method: 'get',
    url: '/teacherStyle/getTeacherQrCode',
    params,
  })
}